import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Viewer3D = ({ wrapperRef, model }) => {
  const { isThreeJsInit, isO3dvInit } = useSelector(store => store.viewerScripts);
  const [blockWidth, setBlockWidth] = React.useState(0);

  useEffect(() => {
    const setBlock = () => {
      setBlockWidth(wrapperRef.current?.clientWidth);
    };
    window.addEventListener('resize', setBlock, true);
    return () => {
      window.removeEventListener('resize', setBlock, true);
    };
  }, []);

  useEffect(() => {
    setBlockWidth(wrapperRef.current?.clientWidth);
  }, [wrapperRef]);

  useEffect(() => {
    if (isO3dvInit && isThreeJsInit) {
      window.OV.Init3DViewerElements();
    }
  }, [isO3dvInit, isThreeJsInit]);

  return (
    <div className="online_3d_viewer"
       style={{
         width: `${blockWidth - 10}px`,
         height: `${blockWidth*4/5}px`,
         border: "1px solid lightgray",
         borderRadius: "6px",
         overflow: "hidden",
       }}
       model={model}
    />
  )
};

export default Viewer3D;
