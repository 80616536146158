import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setScriptState } from "../store/viewerScripts/actions";

const AppInit = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const initThree = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${window.location.origin}/libs/three.min.js`;
      document.head.append(script);
      script.onload = () => {
        dispatch(setScriptState("isThreeJsInit", true));
      };
    };
    const initO3dv = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${window.location.origin}/libs/o3dv.min-dev.js`;
      document.head.append(script);
      script.onload = () => {
        dispatch(setScriptState("isO3dvInit", true));
      };
    };

    initThree();
    initO3dv();
  }, [dispatch]);

  return null;
};

export default AppInit;
